import { TextField } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import { styles } from './styles';

const Input = ({ className, value, size = 'lg', onChange, props = {}, inputProps = {} }) => {
  const classes = styles();

  return (
    <TextField
      hiddenLabel
      className={clsx(classes.input, className, size)}
      value={value}
      onChange={onChange}
      variant="outlined"
      inputProps={inputProps}
      {...props}
    />
  );
};
export default Input;
