import { flatten } from 'lodash';
import React, { Suspense } from 'react';

import LoadingBox from '../components/LoadingBox';
import { ENTITIES } from './entities';

const PAGE_TO_FILETYPE = {
  list: { file: 'index.js', type: 'View', routeProp: 'listRoute' },
  edit: { file: 'Edit.js', type: 'Edit', routeProp: 'editRoute' },
  add: { file: 'Add.js', type: 'Edit', routeProp: 'addRoute' },
};

const ROUTERS = [
  { entity: ENTITIES.benefit, pages: ['list', 'add', 'edit'] },
  { entity: ENTITIES.benefitParticipant, pages: ['list'] },
  { entity: ENTITIES.quiz, pages: ['list', 'add', 'edit'] },
  { entity: ENTITIES.interest, pages: ['list', 'add', 'edit'] },
  { entity: ENTITIES.admin, pages: ['list', 'edit', 'add'] },
  { entity: ENTITIES.participantsStatistic, pages: ['list'] },
  { entity: ENTITIES.participantHistory, pages: ['list'] },
];

export const routers = flatten(
  ROUTERS.map((item) => {
    return item.pages.map((page) => {
      const fileType = PAGE_TO_FILETYPE[page];
      const Page = React.lazy(() => import(`../pages/${item.entity.module}/${fileType.file}`));

      return {
        path: item.entity[fileType.routeProp],
        element: (
          <Suspense fallback={<LoadingBox />}>
            <Page />
          </Suspense>
        ),
        adminModule: item.entity.module,
        type: fileType.type,
        permissions: item.entity.permissions,
      };
    });
  })
);
