import 'react-toastify/dist/ReactToastify.css';
import './styles/global.css';
import './styles/reset.css';

import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import AppLayout from './layout/AppLayout';
import { AdminProvider } from './store/admin';
import { AuthProvider } from './store/auth';
import theme from './styles/theme';

moment.locale('en', { week: { dow: 1 } });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <LocalizationProvider dateAdapter={AdapterMoment}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <AdminProvider>
            <AppLayout />
          </AdminProvider>
        </AuthProvider>
      </ThemeProvider>
    </BrowserRouter>
  </LocalizationProvider>
);
