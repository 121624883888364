import { ENTITIES } from '../../../config/entities';
import APIService from '../service';

export default class ParticipantHistoryAPIService extends APIService {
  constructor() {
    super(ENTITIES.participantHistory);
  }

  setIdToApiName(id) {
    this.apiName = this.apiName.replace(':id', id);
  }

  resetApiName() {
    this.apiName = ENTITIES.participantHistory.api;
  }
}
