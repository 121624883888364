import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import React from 'react';
import { Link } from 'react-router-dom';

import { ENTITIES } from '../../config/entities';
import PageLayout from '../../layout/PageLayout';
import { styles } from './styles';

const PageNotFound = () => {
  const classes = styles();
  const route = ENTITIES.benefit.listRoute;

  return (
    <PageLayout>
      <div className={classes.pageNotFound}>
        <WarningAmberIcon className={classes.icon} />
        <h2 className={classes.title}>Page not found, please enter correct url</h2>
        <Link to={route} className={classes.link}>
          Main table
        </Link>
      </div>
    </PageLayout>
  );
};
export default PageNotFound;
