import { toast } from 'react-toastify';

import { ENTITIES } from '../../../config/entities';
import axios from '../axios';
import APIService from '../service';

export default class QuizAPIService extends APIService {
  constructor() {
    super(ENTITIES.quiz);
  }

  async archive(id) {
    try {
      const res = await axios.put(`/${this.apiName}/${id}/archive`);

      toast.success(`${this.serviceName} archived successfully`);

      return res.data;
    } catch (e) {
      toast.error(`${this.serviceName} archiving error: ${this.errorMessage(e)}`);
    }
  }

  async unarchive(id) {
    try {
      const res = await axios.put(`/${this.apiName}/${id}/unarchive`);

      toast.success(`${this.serviceName} unarchived successfully`);

      return res.data;
    } catch (e) {
      toast.error(`${this.serviceName} unarchiving error: ${this.errorMessage(e)}`);
    }
  }
}
