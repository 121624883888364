import { toast } from 'react-toastify';

import { ENTITIES } from '../../../config/entities';
import axios from '../axios';
import APIService from '../service';

export default class BenefitParticipantsAPIService extends APIService {
  constructor() {
    super(ENTITIES.benefitParticipant);
  }

  setIdToApiName(id) {
    this.apiName = this.apiName.replace(':id', id);
  }

  resetApiName() {
    this.apiName = ENTITIES.benefitParticipant.api;
  }

  registerRowUpdateCallback(callback) {
    this.onRowUpdateCallback = callback;
  }

  async updateStatus(id, body) {
    try {
      console.log('body', body);
      const res = await axios.put(`/${this.apiName}/bulkUpdateWinners`, {
        participantIds: [id],
        status: body.status,
      });

      toast.success(`Participant status successfully updated to ${body.status}`);

      if (this.onRowUpdateCallback) {
        this.onRowUpdateCallback();
      }

      return res.data;
    } catch (e) {
      toast.error(`${this.serviceName} updating status error: ${this.errorMessage(e)}`);
    }
  }

  async getAll(params = {}) {
    const queryParams = this._paramsToQueryParams({ ...params, pageSize: 1000 });

    const res = await axios.get(`/${this.apiName}?${queryParams}`);

    return res.data;
  }

  async selectWinners(data) {
    const res = await axios.post(`/${this.apiName}/selectWinners`, data);

    toast.success(`Winners successfully selected`);

    return res.data;
  }

  async discardWinners() {
    const res = await axios.post(`/${this.apiName}/discardWinners`);

    toast.success(`Winners successfully discarded`);

    return res.data;
  }

  async publishWinners(data) {
    const res = await axios.post(`/${this.apiName}/publishWinners`, data);

    toast.success(`Winners successfully published`);

    return res.data;
  }

  async unpublishWinners(data) {
    const res = await axios.post(`/${this.apiName}/unpublishWinners`, data);

    toast.success(`Winners successfully unpublished`);

    return res.data;
  }
}
