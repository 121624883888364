import axios from 'axios';
import { toast } from 'react-toastify';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  headers: {
    'X-DeviceType': 'Admin',
    'X-OperatingSystem': 'None',
    'X-Language': 'EN',
  },
});

const onRequest = (request) => {
  const token = localStorage.getItem('token');

  if (!token) {
    return request;
  }

  if (request.headers && token) {
    request.headers['Authorization'] = `Bearer ${token}`;
  }

  return request;
};

const onRequestError = (error) => {
  return Promise.reject(error);
};

const onResponse = (response) => {
  return response;
};

const onResponseError = (error) => {
  toast.error(error?.response?.data?.message || error?.message);

  return Promise.reject(error);
};

instance.interceptors.request.use(onRequest, onRequestError);
instance.interceptors.response.use(onResponse, onResponseError);

export const REACT_APP_API_HOST = process.env.REACT_APP_API_HOST;
export default instance;
