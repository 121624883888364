import { pink } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

export const PRIMARY_LIGHT = pink[400];
export const PRIMARY_MAIN = pink[500];
export const PRIMARY_DARK = pink[600];

const theme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_MAIN,
      light: PRIMARY_LIGHT,
      dark: PRIMARY_DARK,
      contrastText: '#fff',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 1024,
      xl: 1250,
    },
  },
});
export default theme;
