import { pick, reduce } from 'lodash';
import qs from 'qs';
import { toast } from 'react-toastify';

import axios from './axios';

export default class APIService {
  constructor(entity) {
    this.apiName = entity?.api;
    this.serviceName = entity?.name;
    this.acceptedParams = ['page', 'perPage', 'pageSize', 'sort', 'filter', 'sortDir'];
    this.paramsToQuery = {
      page: 'page',
      perPage: 'perPage',
      pageSize: 'perPage',
      sort: 'sort',
      filter: 'filter',
      sortDir: 'sortDir',
    };
  }

  errorMessage(e) {
    return `${e?.message}. ${e?.response?.data?.message ? 'Errors: ' + e?.response?.data?.message?.join(', ') : ''}`;
  }

  _paramsToQueryParams(params) {
    const acceptedParams = pick(params, this.acceptedParams);
    const queryParams = reduce(
      acceptedParams,
      (acc, val, key) => {
        if (key === 'sortDir' && val) {
          acc[this.paramsToQuery[key]] = val.toUpperCase();
          return acc;
        }

        acc[this.paramsToQuery[key]] = val;
        return acc;
      },
      {}
    );

    return qs.stringify(queryParams, { indices: false });
  }

  async get(id) {
    const res = await axios.get(`/${this.apiName}/${id}`);

    return res.data;
  }

  async post(form) {
    try {
      const res = await axios.post(`/${this.apiName}`, form);

      toast.success(`${this.serviceName} successfully added`);

      return res.data;
    } catch (e) {
      toast.error(`${this.serviceName} creating error: ${this.errorMessage(e)}`);
    }
  }

  async put(id, form) {
    try {
      const res = await axios.put(`/${this.apiName}/${id}`, form);

      toast.success(`${this.serviceName} successfully updated`);

      return res.data;
    } catch (e) {
      toast.error(`${this.serviceName} updating error: ${this.errorMessage(e)}`);
    }
  }

  async updateStatus(id, body) {
    try {
      const res = await axios.put(`/${this.apiName}/${id}`, body);
      const field = Object.keys(body)[0] || 'status';

      toast.success(`${this.serviceName} ${field} successfully updated`);

      return res.data;
    } catch (e) {
      toast.error(`${this.serviceName} updating status error: ${this.errorMessage(e)}`);
    }
  }

  async getAll(params = {}) {
    const queryParams = this._paramsToQueryParams(params);

    const res = await axios.get(`/${this.apiName}?${queryParams}`);

    return res.data;
  }

  async getTotals(params = {}) {
    const queryParams = this._paramsToQueryParams(params);

    const res = await axios.get(`/${this.apiName}/totals?${queryParams}`);

    return res.data;
  }

  async export(params = {}, archived = false, path = 'export') {
    const queryParams = this._paramsToQueryParams(params);

    const res = await axios.get(`/${this.apiName}/${path}${archived ? '/archived' : ''}?${queryParams}`, {
      headers: { Accept: 'application/xlsx' },
      responseType: 'blob',
    });
    const blob = res.data;
    const filename = `${this.serviceName.toLowerCase().replace(' ', '-')}-export.xlsx`;

    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, filename);
    } else {
      var downloadLink = window.document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(
        new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      );
      downloadLink.download = filename;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }

    return res.data;
  }
}
