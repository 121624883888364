import { Checkbox } from '@material-ui/core';
import React from 'react';

import RowLayout from '../../../layout/RowLayout';
import InputForm from '../../UI/InputForm';
import TitleRow from '../TitleRow';
import { styles } from './styles';

const InputRow = ({ title, isCorrect, titleHint, name, control, props, inputProps = {}, passHint, unlimited }) => {
  const classes = styles();

  return (
    <RowLayout>
      <TitleRow>
        <p className={isCorrect && classes.correct}>
          {title}
          {isCorrect && <b className={classes.correct}> (Correct)</b>}
          <span>{props.required || inputProps.required ? ' *' : null}</span>
        </p>{' '}
        <mark>{titleHint}</mark>
      </TitleRow>
      <div>
        <InputForm className={classes.input} name={name} control={control} props={props} inputProps={inputProps} />
        {isCorrect && <p className={classes.inputHint}>Please insert the right answer here</p>}
      </div>
      {!!unlimited && (
        <div className={classes.unlimitedCheckbox}>
          <Checkbox color="info" />
          Unlimited
        </div>
      )}
      {!!passHint && (
        <div className={classes.passHint}>
          <ul>
            <li>Minimum of 8 characters</li>
            <li>
              <p>At least one number or symbol</p>
              <p>{'(~!@#$%^&*_-+=`|\\(){}[]:;\'"<>,.?/)'}</p>
            </li>
            <li>At least one uppercase symbol A-Z</li>
            <li>Do not use email address</li>
            <li>Do not use previous passwords</li>
          </ul>
        </div>
      )}
    </RowLayout>
  );
};
export default InputRow;
