import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import React from 'react';

import { styles } from './styles';

const FunctionName = ({ checked, hint, onChange, disabled }) => {
  const classes = styles();

  return (
    <FormGroup className={classes.switch}>
      <FormControlLabel
        control={<Switch disabled={disabled} checked={checked} onChange={onChange} />}
        label={hint ? `${hint} ${checked ? 'active' : 'inactive'}` : ''}
      />
    </FormGroup>
  );
};
export default FunctionName;
