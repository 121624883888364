import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles(() => ({
  pageTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 0 32px 0',
  },
  title: {
    fontSize: '32px',
    fontWeight: 'bold',
  },
  subtitle: {
    marginTop: '10px',
    fontSize: '16px',
    fontWeight: 500,
    color: '#999999',
  },
  actions: {
    display: 'flex',
    gap: '10px',
  },
  buttons: {
    display: 'flex',
    gap: '10px',
  },
}));
