import { Button } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

import { styles } from './styles';

const PageTitle = ({ title, subtitle, href, linkTitle, actions = [] }) => {
  const classes = styles();

  return (
    <section className={classes.pageTitle}>
      <div>
        <h1 className={classes.title}>{title}</h1>
        {subtitle ? <h2 className={classes.subtitle}>{subtitle}</h2> : null}
      </div>
      <div className={classes.buttons}>
        {actions.length > 0 ? (
          <div className={classes.actions}>
            {actions.map((action) => (
              <Button
                key={action.title}
                disabled={action.disabled}
                onClick={action.handler}
                startIcon={action.icon}
                size="large"
                variant={action.variant || 'contained'}
              >
                {action.title}
              </Button>
            ))}
          </div>
        ) : null}
        {href ? (
          <Link className={classes.link} to={href}>
            <Button size="large" variant="contained">
              {linkTitle}
            </Button>
          </Link>
        ) : null}
      </div>
    </section>
  );
};
export default PageTitle;
