import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import React from 'react';
import { Controller } from 'react-hook-form';

import { styles } from './styles';

const Textarea = ({ className, name, control, props = {} }) => {
  const classes = styles();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <TextField
          variant="outlined"
          multiline
          rows={4}
          className={clsx(classes.textarea, className, 'lg')}
          value={value}
          onChange={onChange}
          {...props}
        />
      )}
    />
  );
};
export default Textarea;
