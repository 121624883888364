import { makeStyles } from '@material-ui/core/styles';

import { PRIMARY_MAIN } from '../../../styles/theme';

export const styles = makeStyles(() => ({
  titleRow: {
    width: '160px',
    fontWeight: '700',
    fontSize: '14px',
    padding: '12px 0',
    '& span': {
      fontWeight: '500',
      fontSize: '14px',
      color: PRIMARY_MAIN,
      margin: '4px 0 0 0',
    },
    '& mark': {
      display: 'block',
      fontWeight: '400',
      fontSize: '14px',
      color: '#CCCCCC',
      margin: '4px 0 0 0',
      backgroundColor: 'transparent',
    },
  },
}));
