import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles(() => ({
  myProfileBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '32px',
    padding: '42px 48px 64px',
    border: '1px solid rgba(228, 228, 228, 1)',
    backgroundColor: '#fff',
  },
  myProfileItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontWeight: 700,
    fontSize: '24px',
    margin: '0 0 32px 0',
  },
  passBox: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: '40px',
  },
  passCol: {
    display: 'flex',
    flexDirection: 'column',
  },
  btn: {
    margin: '0 0 0 auto',
  },
}));
