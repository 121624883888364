import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles(() => ({
  row: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    margin: '0 0 32px 0',
    '&.top': {
      alignItems: 'flex-start',
    },
  },
}));
