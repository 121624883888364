import { toast } from 'react-toastify';

import { ENTITIES } from '../../../config/entities';
import axios from '../axios';
import APIService from '../service';

export default class BenefitAPIService extends APIService {
  constructor() {
    super(ENTITIES.benefit);
  }

  async getCountries() {
    const res = await axios.get('/countries/supported');

    return res.data;
  }

  async duplicate(id) {
    const res = await axios.post(`/${this.apiName}/duplicate/${id}`, {});

    toast.success(`${this.serviceName} successfully duplicated`);

    return res.data;
  }

  async archive(id) {
    try {
      const res = await axios.put(`/${this.apiName}/${id}/archive`);

      toast.success(`${this.serviceName} archived successfully`);

      return res.data;
    } catch (e) {
      toast.error(`${this.serviceName} archiving error: ${this.errorMessage(e)}`);
    }
  }

  async unarchive(id) {
    try {
      const res = await axios.put(`/${this.apiName}/${id}/unarchive`);

      toast.success(`${this.serviceName} unarchived successfully`);

      return res.data;
    } catch (e) {
      toast.error(`${this.serviceName} unarchiving error: ${this.errorMessage(e)}`);
    }
  }
}
