import { makeStyles } from '@material-ui/core/styles';

import { PRIMARY_MAIN } from '../../styles/theme';

export const styles = makeStyles(() => ({
  root: {
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loginForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: '770px',
    backgroundColor: 'rgba(245, 247, 252, 1)',
    border: '1px solid rgba(228, 228, 228, 1)',
    margin: '0 auto',
    padding: '40px 16px 140px',
  },
  logo: {
    maxWidth: '275px',
    margin: '0 0 30px 0',
  },
  title: {
    fontSize: '32px',
    fontWeight: 'bold',
    margin: '0 0 20px 0',
  },
  form: {
    width: '100%',
    maxWidth: '400px',
    display: 'flex',
    gap: '10px',
    flexDirection: 'column',
  },
  input: {
    '&.MuiInputBase-root': {
      backgroundColor: '#fff',
      borderRadius: '0',
      border: '1px solid rgba(228, 228, 228, 1)',
      '&:hover': {
        backgroundColor: '#fff',
      },
      '&:before': {
        content: 'none',
      },
      '&:after': {
        borderColor: PRIMARY_MAIN,
        borderBottomWidth: '2px',
      },

      '&.Mui-focused': {
        backgroundColor: '#fff',
      },
    },
    '& .MuiInputBase-input': {
      fontSize: '14px',
      lineHeight: '1',
      padding: '16px 10px 12px',
      '&:-webkit-autofill': {
        '-webkit-text-fill-color': '#000',
        '-webkit-box-shadow': '0 0 0px 1000px #fff inset',
      },
    },
    '& .MuiInputAdornment-root': {
      margin: '0 !important',
    },
  },
  btn: {
    margin: '40px 0 0 0',
  },
}));
