import LockIcon from '@mui/icons-material/Lock';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import logo from '../../assets/logo.png';
import Button from '../../components/UI/Button';
import { useAuth } from '../../store/auth';
import { styles } from './styles';

const CreatePassword = ({ title }) => {
  const classes = styles();
  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get('token');
  const navigate = useNavigate();
  const { resetPassword } = useAuth();
  const [form, setForm] = useState({
    password: '',
    passwordConfirmation: '',
  });

  const handleChange = (prop) => (event) => {
    setForm({ ...form, [prop]: event.target.value });
  };

  const handleForm = (e) => {
    e.preventDefault();

    if (form.password !== form.passwordConfirmation) {
      return toast.error(`Passwords are not equal`);
    }

    if (!token) {
      return toast.error(`Token not found`);
    }

    resetPassword({
      resetToken: token,
      newPassword: form.password,
    }).then(() => {
      navigate('/');
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.loginForm}>
        <img className={classes.logo} src={logo} alt="" />
        <h1 className={classes.title}>{title || 'Create your password'}</h1>
        <form className={classes.form} onSubmit={handleForm} action="">
          <FormControl variant="filled">
            <FilledInput
              type="password"
              placeholder="Password"
              className={classes.input}
              onChange={handleChange('password')}
              required
              autoComplete="current-password"
              inputProps={{ minLength: 6 }}
              startAdornment={
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl variant="filled">
            <FilledInput
              type="password"
              placeholder="Password confirmation"
              className={classes.input}
              onChange={handleChange('passwordConfirmation')}
              required
              autoComplete="current-password"
              inputProps={{ minLength: 6 }}
              startAdornment={
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              }
            />
          </FormControl>
          <Button className={classes.btn} type="submit">
            Submit
          </Button>
        </form>
      </div>
    </div>
  );
};
export default CreatePassword;
