import React, { createContext, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AuthAPI } from '../API/REST/auth';

const authContext = createContext();

const useProviderAuth = () => {
  const navigate = useNavigate();

  const login = useCallback(
    async (values) => {
      return await AuthAPI.login(values).then((res) => {
        const token = res?.accessToken;
        const refreshToken = res?.refreshToken;
        localStorage.setItem('token', token);
        localStorage.setItem('refresh_token', refreshToken);
      });
    },
    [navigate]
  );

  const logout = useCallback(async () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    navigate('/');
  }, [navigate]);

  const sendResetPasswordEmail = useCallback(
    async (data) => {
      return await AuthAPI.sendResetPasswordEmail(data).then((res) => {
        if (res.status === 200 || res.status === 201) {
          toast.success(`Email with reset link successfully sent. Check your inbox.`);
        }

        return res.data;
      });
    },
    [navigate]
  );

  const resetPassword = useCallback(
    async (data) => {
      return await AuthAPI.resetPassword(data).then((res) => {
        if (res.status === 200 || res.status === 201) {
          toast.success(`Your password has been successfully reset`);
        }

        return res.data;
      });
    },
    [navigate]
  );

  // useEffect(() => {
  //   // Session token should expire in 6 hours
  //   const storage = localStorage.getItem('token');
  //   if (moment().unix() > storage?.expires) {
  //     console.log('here3');
  //     logout('Your session has expired');
  //   }
  // }, [location.pathname, logout]);

  return {
    login,
    logout,
    resetPassword,
    sendResetPasswordEmail,
  };
};

export const AuthProvider = ({ children }) => {
  const authProvider = useProviderAuth();
  return <authContext.Provider value={authProvider}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};
