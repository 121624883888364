import { Close } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { isNil } from 'lodash';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { API } from '../../API';
import { ENTITIES } from '../../config/entities';
import InputRow from '../Rows/InputRow';
import SwitchRow from '../Rows/SwitchRow';
import TextareaRow from '../Rows/TextareaRow';

const GenerateTokenDialog = ({ open, close }) => {
  const [idToken, setIdToken] = useState('');
  const { control, reset, setValue, watch, handleSubmit } = useForm({
    defaultValues: {
      email: '',
      phone: '',
      sub: '',
      name: '',
      employee: true,
    },
  });
  const url = watch('url');

  const onSubmit = (data) => {
    const body = Object.entries(data).reduce((acc, [key, value]) => {
      if (isNil(value) || value === '') return acc;
      acc[key] = value;
      return acc;
    }, {});

    API[ENTITIES.admin.name].generateIdToken(body).then((res) => {
      if (res.idToken) {
        setIdToken(res.idToken);
        setValue('idToken', res.idToken);

        const url = `${process.env.REACT_APP_HOST}?idToken=${res.idToken}`;
        setValue('url', url);
      }
    });
  };

  const onClose = () => {
    setIdToken('');
    reset();
    close();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Generate idToken</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        {idToken ? (
          <DialogContent>
            <TextareaRow title="idToken" name="idToken" control={control} />
            <TextareaRow title="URL with idToken" name="url" control={control} />
          </DialogContent>
        ) : (
          <DialogContent>
            <InputRow title="Email" control={control} name="email" props={{ type: 'email' }} />
            <InputRow title="Phone" control={control} name="phone" props={{}} />
            <InputRow title="Sub" control={control} name="sub" props={{ type: 'number' }} />
            <InputRow title="Name" control={control} name="name" props={{}} />
            <SwitchRow title="Is employee?" name="employee" control={control} />
          </DialogContent>
        )}
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          {idToken ? (
            <Button onClick={() => window.open(url, '_blank')} variant="contained">
              Open URL
            </Button>
          ) : (
            <Button type="submit" disabled={idToken} variant="contained">
              Generate
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default GenerateTokenDialog;
