import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

import { styles } from './styles';

const LoadingBox = () => {
  const classes = styles();

  return (
    <div className={classes.loadingBox}>
      <CircularProgress />
    </div>
  );
};
export default LoadingBox;
