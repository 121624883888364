import { ENTITIES } from '../config/entities';
import AdminAPIService from './REST/entities/admin';
import BenefitParticipantsAPIService from './REST/entities/benefitParticipants';
import BenefitAPIService from './REST/entities/benefits';
import InterestAPIService from './REST/entities/interests';
import ParticipantHistoryAPIService from './REST/entities/participantHistory';
import ParticipantsStatisticsAPIService from './REST/entities/participantsStatistic';
import QuizAPIService from './REST/entities/quiz';

export const BenefitAPI = new BenefitAPIService();
export const BenefitParticipantsAPI = new BenefitParticipantsAPIService();
export const ParticipantsStatisticsAPI = new ParticipantsStatisticsAPIService();
export const ParticipantHistoryAPI = new ParticipantHistoryAPIService();
export const QuizAPI = new QuizAPIService();
export const InterestAPI = new InterestAPIService();
export const AdminAPI = new AdminAPIService();

export const API = {
  [ENTITIES.benefit.name]: BenefitAPI,
  [ENTITIES.benefitParticipant.name]: BenefitParticipantsAPI,
  [ENTITIES.participantsStatistic.name]: ParticipantsStatisticsAPI,
  [ENTITIES.participantHistory.name]: ParticipantHistoryAPI,
  [ENTITIES.quiz.name]: QuizAPI,
  [ENTITIES.interest.name]: InterestAPI,
  [ENTITIES.admin.name]: AdminAPI,
};
