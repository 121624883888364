import React from 'react';

import RowLayout from '../../../layout/RowLayout';
import Textarea from '../../UI/Textarea';
import TitleRow from '../TitleRow';

const TextareaRow = ({ title, titleHint, name, className, control, props }) => {
  return (
    <RowLayout align="top">
      <TitleRow>
        {title} {titleHint && <span>({titleHint})</span>}
      </TitleRow>
      <Textarea className={className} name={name} control={control} props={props} />
    </RowLayout>
  );
};
export default TextareaRow;
