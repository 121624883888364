import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles(() => ({
  root: {
    display: 'flex',
    flex: '1',
    overflow: 'hidden',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: 'calc(100% - 240px)',
    overflow: 'auto',
    padding: '32px 24px 0',
    backgroundColor: '#f6f6fa',
  },
}));
