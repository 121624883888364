// module - permission name of entity in DB
// api - name of entity for URL building (only for REST API)
// name - name of entity for notifications
// listTitle - title for page with list of entities
// listRoute - url for page with list of entities

export const ENTITIES = {
  benefit: {
    module: 'Benefits',
    name: 'Benefit',
    listTitle: 'Benefits',
    listRoute: '/benefits',
    addRoute: '/add-benefit',
    addTitle: 'Add benefit',
    editRoute: '/benefit/:id',
    api: 'ap/benefits',
    permissions: ['ROOT', 'ADMIN'],
  },

  benefitParticipant: {
    module: 'BenefitParticipants',
    name: 'Benefit participants',
    listTitle: 'Benefit participants',
    listRoute: '/benefit-participants/:id',
    api: 'ap/benefits/:id/participants',
    permissions: ['ROOT', 'ADMIN'],
  },

  participantsStatistic: {
    module: 'ParticipantsStatistics',
    name: 'Participants statistics',
    listTitle: 'Participants statistics',
    listRoute: '/participants-statistics',
    api: 'ap/participantStatistics',
    permissions: ['ROOT', 'ADMIN'],
  },

  participantHistory: {
    module: 'ParticipantsHistory',
    name: "Participant's history",
    listTitle: "Participant's history",
    listRoute: '/participants-statistics/:id/history',
    api: 'ap/participantStatistics/:id/history',
    permissions: ['ROOT', 'ADMIN'],
  },

  quiz: {
    module: 'Quizes',
    name: 'Quiz question',
    listTitle: 'Quiz questions',
    listRoute: '/quizes',
    addRoute: '/add-quiz',
    addTitle: 'Add quiz',
    editRoute: '/quiz/:id',
    api: 'ap/quizQuestions',
    permissions: ['ROOT', 'ADMIN'],
  },

  interest: {
    module: 'Interests',
    name: 'Interest',
    listTitle: 'Interests',
    listRoute: '/interests',
    addRoute: '/add-interest',
    addTitle: 'Add interest',
    editRoute: '/interest/:id',
    api: 'ap/interests',
    permissions: ['ROOT', 'ADMIN'],
  },

  admin: {
    module: 'Administrators',
    name: 'Administrator',
    listTitle: 'Administrators',
    listRoute: '/administrators',
    addRoute: '/add-administrator',
    addTitle: 'Add administrator',
    editRoute: '/administrator/:id',
    api: 'ap/admins',
    permissions: ['ROOT', 'ADMIN'],
  },
};
