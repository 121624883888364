import { toast } from 'react-toastify';

import { ENTITIES } from '../../../config/entities';
import axios from '../axios';
import APIService from '../service';

export default class AdminAPIService extends APIService {
  constructor() {
    super(ENTITIES.admin);
  }

  async generateIdToken(data) {
    const res = await axios.post('/ap/auth/testIdToken', data);

    toast.success(`idToken successfully generated.`);

    return res.data;
  }

  async updatePassword(id, data) {
    const res = await axios.put(`/${this.apiName}/${id}/passwordUpdate`, data);

    toast.success(`${this.serviceName} password successfully updated`);

    return res.data;
  }
}
