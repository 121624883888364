import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { styles } from './styles';

const NavAccordion = ({ title, links }) => {
  const classes = styles();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const listRef = useRef(null);

  useEffect(() => {
    const isCurrent = links.some((el) => el.href === location.pathname);
    isCurrent && setOpen(true);
  }, [links, location.pathname]);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleNavLinks = (item, key) => {
    return (
      <NavLink className={classes.sublink} to={item.href} key={key} end>
        {item.title}
      </NavLink>
    );
  };

  return (
    <>
      {listRef.current?.firstChild && (
        <ListItemButton onClick={handleClick} className={clsx(classes.listBtn, open && classes.listBtnOpened)}>
          {open ? <ExpandLess /> : <ExpandMore />}
          <ListItemText primary={title} />
        </ListItemButton>
      )}

      <Collapse in={open}>
        <List component="div" className={classes.list} ref={listRef}>
          {links.map((item, key) => handleNavLinks(item, key))}
        </List>
      </Collapse>
    </>
  );
};
export default NavAccordion;
