import React from 'react';
import { Controller } from 'react-hook-form';

import RowLayout from '../../../layout/RowLayout';
import Switch from '../../UI/Switch';
import TitleRow from '../TitleRow';

const SwitchRow = ({ title, name, control, hint }) => {
  return (
    <RowLayout>
      <TitleRow>{title}</TitleRow>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => <Switch hint={hint} checked={value || false} onChange={onChange} />}
      />
    </RowLayout>
  );
};
export default SwitchRow;
