import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles(() => ({
  confirmSection: {
    position: 'fixed',
    bottom: '0',
    left: '240px',
    width: 'calc(100vw - 240px - 17px)',
    height: '70px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '24px',
    padding: '0 32px',
    background: '#FAFAFC',
    borderTop: '1px solid #E4E4E4',
    zIndex: '10',
  },
}));
