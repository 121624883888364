import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles(() => ({
  textarea: {
    '&.lg': {
      width: '480px',
    },
    '& .MuiInputBase-root': {
      padding: '0',
      transition: '0.2s',
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px',
        },
      },
      '& textarea.MuiInputBase-input': {
        padding: '12px',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        transition: '0.2s',
      },
    },
  },
}));
