import axios from './axios';

const login = async (body) => {
  const res = await axios.post(`/auth/login`, body);

  return res.data;
};

const refresh = async () => {
  const res = await axios.put(`/auth/refresh`, {
    refreshToken: localStorage.getItem('refresh_token'),
  });

  return res.data;
};

const logout = async () => {
  const res = await axios.delete(`/auth/logout`, {});

  return res.data;
};

const resetPassword = async (body) => {
  const res = await axios.put(`/ap/passwordReset/reset`, body);

  return res;
};

const sendResetPasswordEmail = async (body) => {
  const res = await axios.post(`/ap/passwordReset/sendResetLink`, body);

  return res;
};

export const AuthAPI = { login, logout, refresh, resetPassword, sendResetPasswordEmail };
