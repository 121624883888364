import { makeStyles } from '@material-ui/core/styles';

import { PRIMARY_LIGHT, PRIMARY_MAIN } from '../../styles/theme';

export const styles = makeStyles(() => ({
  pageNotFound: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    flex: '1',
  },
  icon: {
    margin: '0 0 16px 0',
    '&.MuiSvgIcon-root': {
      fontSize: '64px',
    },
  },
  title: {
    maxWidth: '300px',
    textAlign: 'center',
    margin: '0 0 24px 0',
  },
  link: {
    minWidth: '220px',
    color: '#fff',
    backgroundColor: PRIMARY_MAIN,
    borderRadius: '5px',
    padding: '16px',
    textAlign: 'center',
    transition: '0.2s',
    '&:hover': {
      backgroundColor: PRIMARY_LIGHT,
    },
  },
}));
