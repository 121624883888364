import clsx from 'clsx';
import { isUndefined } from 'lodash';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { menu } from '../../config/menu';
import { useAdmin } from '../../store/admin';
import NavAccordion from './NavAccordion';
import { styles } from './styles';

const Nav = () => {
  const classes = styles();
  const { profileInfo } = useAdmin();
  const { pathname } = useLocation();

  const handleNavLinks = (item, key) => {
    const { listRoute, addRoute = '', editRoute = '' } = item.data;
    const isEntityRoute =
      [listRoute, addRoute].includes(pathname) ||
      pathname.includes(listRoute) ||
      (editRoute && pathname.includes(editRoute?.replace('/:id', '')));

    if (item.links) {
      return <NavAccordion title={item.title} links={item.links} profileInfo={profileInfo} key={key} />;
    } else {
      return (
        <NavLink
          active="true"
          className={({ isActive }) =>
            isActive || isEntityRoute ? clsx(classes.link, classes.linkActive) : classes.link
          }
          to={item.href}
          key={key}
          end
        >
          {item.title}
        </NavLink>
      );
    }
  };

  return (
    <div className={classes.nav}>
      {menu
        .filter((item) => (item ? item.data.permissions.includes(profileInfo.role) : true))
        .map((item, key) => {
          if (isUndefined(item)) {
            return profileInfo.role !== 'COMPANY_ADMIN' ? <hr key={key} className={classes.hr} /> : null;
          }
          return handleNavLinks(item, key);
        })}
    </div>
  );
};
export default Nav;
