import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles(() => ({
  switch: {
    '& .MuiTypography-root': {
      fontSize: '14px',
      color: 'rgb(204, 204, 204)',
    },
  },
}));
