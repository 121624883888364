import clsx from 'clsx';
import React from 'react';

import { styles } from './styles';

const RowLayout = ({ align, children }) => {
  const classes = styles();

  return <div className={clsx(classes.row, align)}>{children}</div>;
};
export default RowLayout;
