import { isUndefined } from 'lodash';

import { ENTITIES } from './entities';

const MENU = [
  ENTITIES.benefit,
  ENTITIES.quiz,
  ENTITIES.interest,
  ENTITIES.admin,
  undefined,
  ENTITIES.participantsStatistic,
];

const buildItem = (item) => ({
  href: item.listRoute,
  title: item.listTitle,
  adminModule: item.module,
  data: item,
});

export const menu = MENU.map((item) => {
  if (isUndefined(item)) return item;

  return buildItem(item);
});
