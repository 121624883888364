import React from 'react';

import { styles } from './styles';

const ConfirmStickySection = ({ children }) => {
  const classes = styles();

  return <div className={classes.confirmSection}>{children}</div>;
};
export default ConfirmStickySection;
