import { makeStyles } from '@material-ui/core/styles';

import { PRIMARY_MAIN } from '../../../styles/theme';

export const styles = makeStyles(() => ({
  listBtn: {
    '&.MuiButtonBase-root': {
      padding: '26px 16px 26px 8px',
      transition: '0.2s',
      '&:hover': {
        color: PRIMARY_MAIN,
        backgroundColor: '#fff',
      },
    },
    '& .MuiSvgIcon-root': {
      fontSize: '32px',
      transform: 'rotate(-90deg)',
      color: 'rgba(228, 228, 228, 1)',
    },
  },
  listBtnOpened: {
    '& .MuiSvgIcon-root': {
      transform: 'rotate(180deg)',
    },
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    background: 'rgba(242, 242, 242, 0.6)',
    '&.MuiList-root': {
      padding: '0',
    },
  },
  sublink: {
    position: 'relative',
    padding: '16px 16px 16px 50px',
    transition: '0.2s',
    '&:hover': {
      color: PRIMARY_MAIN,
    },
    '&.active': {
      color: PRIMARY_MAIN,
      '&:before': {
        content: "''",
        position: 'absolute',
        top: '0',
        left: '0',
        width: '4px',
        height: '100%',
        backgroundColor: PRIMARY_MAIN,
      },
    },
  },
}));
