import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles(() => ({
  loadingBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '1',
  },
}));
